.subheading,
.heading span {
  display: none;
}

.sectionContainer {
  width: 100vw;
}
.heading {
  font-size: 32px;
  font-weight: 500;
  line-height: 1.25;
  color: var(--text-color);
  text-align: center;
  margin-bottom: 12px;
}

@media screen and (min-width: 1024px) {
  .heading {
    font-size: 46px;
  }
  .subheading {
    font-size: 18px;
    line-height: 1.5;
    color: var(--text-color);
    text-align: center;
    max-width: 600px;
  }
  .subheading,
  .heading span {
    display: initial;
  }
}
